<script setup>
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

const store = useStore()
// computed
const getFromSub3Config = computed(() => store.getters['system/getFromSub3Config'])
console.log(getFromSub3Config.value)
// methods
function onChangeOption (e) {
  store.dispatch('system/setSubThreeOptions', {
    id: e.target.value,
    attr: e.target.dataset.step,
    checked: e.target.checked
  })
}
</script>

<template>
  <div class="row">
    <div class="col-md-12" v-for="(step, index) in getFromSub3Config" :key="index">
      <p><strong>{{ step.name }}:</strong></p>
      <div class="row">
        <div v-for="(option, index) in step.options" :key="index" class="col-sm-4">
          <div  v-if="option.type === 'select'" class="form-check">
            <input
              type="radio"
              :id="option.id"
              :value="option.id"
              :name="option.dname"
              :data-step="step.id"
              :checked="option.selected"
              class="form-check-input"
              :disabled="option.disabled"
              @change="onChangeOption"
            >
            <label class="form-check-label" :for="option.id">
              {{ option.name }} <tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></tooltip>
            </label>
          </div>
                  <div v-else-if="option.type === 'check'" class="form-check" >
                    <input
                      type="checkbox"
                      :id="option.id"
                      :value="option.id"
                      :name="step.id"
                      :data-step="step.id"
                      :checked="option.selected"
                      class="form-check-input"
                      :disabled="option.disabled"
                      @change="onChangeOption"
                    >
                    <label class="form-check-label" :for="option.id">
                      {{ option.name }} <tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></tooltip>
                    </label>
                  </div>
        </div>
      </div>
    </div>
  </div>

</template>
